.contact-container {
  margin: 10rem auto 4rem auto;
  width: 80%;
  min-height: 100vh;
}

form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  direction: rtl;
}

.email,
.tel {
  direction: ltr;
}

input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

input:focus {
  border: 2px solid rgba(0, 206, 158, 1);
}

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

textarea:focus {
  border: 2px solid rgba(0, 206, 158, 1);
}

label {
  margin-top: 1rem;
  margin-left: auto;
}

input[type="submit"] {
  margin: 2rem auto;
  max-width: 200px;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(45, 42, 48, 1) -50%,
    rgba(234, 160, 63, 1) 50%
  );
  color: white;
  border: none;
}

.send-btn {
  font-family: "Noto Kufi Arabic", sans-serif;
}
