.downloads-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 20px;
}

.pdf-file {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: calc(100% - 40px);
  max-width: 320px;
  height: 400px;
  margin: 0 20px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.pdf-preview img {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 200px;
  display: block;
}

.pdf-info {
  padding: 16px;
  text-align: center;
  flex: 1;
}

.pdf-name {
  margin-bottom: 12px;
  font-weight: bold;
}

.download-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #0056b3;
}

/* Responsive Layout */
@media (min-width: 576px) {
  .pdf-file {
    width: calc(50% - 40px);
  }
}

@media (min-width: 768px) {
  .pdf-file {
    width: calc(33.333% - 40px);
  }
}

@media (min-width: 992px) {
  .pdf-file {
    width: calc(25% - 40px);
  }
}
