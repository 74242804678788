.best {
  width: 100%;
  margin: 4rem 0;
  text-align: center;
}

.best h2 {
  font-family: "Noto Kufi Arabic", sans-serif;
  font-weight: 400;
  padding: 1rem;
}

.best > div {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.best > div p {
  margin: 1rem 2rem;
  font-size: 1.2rem;
}

.best .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.2rem;
  padding: 1rem;
}

.best img {
  width: 100%;
}

.btnBest {
  margin: 2rem 1rem;
  width: 150px;
}
@media screen and (max-width: 940px) {
  .best {
    margin: 1rem 0;
  }

  .best > div p {
    margin: 1rem;
    font-size: 1rem;
  }

  .best .container {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
}

.bestRatedImgSliderContainer {
  height: 30rem;
  margin: 0 auto;
  padding: 0.5rem;
  border: 0.1rem solid #2d2a30;
  border-radius: 1rem;
}

@media screen and (min-width: 479px) {
  .best > div p {
    margin: 1rem 0.5rem;
  }
  .bestRatedImgSliderContainer {
    width: 680px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 478px) {
  .best > div p {
    margin: 1rem 0.5rem;
  }
  .bestRatedImgSliderContainer {
    width: 380px;
  }
}
