.error-container {
  margin: 10rem auto 4rem auto;
  width: 80%;
  min-height: 60vh;
}

.error-wrapper {
  display: grid;
  place-items: center;
}
