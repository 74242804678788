.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.show {
  opacity: 1;
  visibility: visible;
}

.modal span {
  margin-left: 16px;
  font-size: 24px;
  color: #eaa03f;
}

.modal .modal-form {
  background-color: rgb(253, 253, 253);
  padding: 2rem;
  width: 90%;
  max-width: 500px; /* Limit the width of the modal on larger screens */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  direction: ltr;
  border: 1px solid;
}

.modal-description {
  margin-top: 1rem;
  font-size: smaller;
  margin: 1rem auto 0;
  text-align: center;
  max-width: 25rem;
}

.modal label {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

.modal input {
  width: 100%; /* Ensure inputs take up full width */
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
}

.modal input:focus {
  border-color: #0077ff;
}

.modal-close {
  margin-left: auto;
  cursor: pointer;
}

.brochure_name {
  display: none;
}

.modal-btn {
  width: 100%; /* Ensure button takes up full width */
}

@media only screen and (max-width: 768px) {
  /* Adjust the modal styles for smaller screens */
  .modal form {
    width: 90%;
    max-width: 90%;
  }
  .modal-btn {
    width: 100%;
  }
}
