.document-title {
  text-align: center;
  border: 1px solid hidden;
  border-radius: 0.4rem;
  background: #eaa03f;
  margin: 3rem 2rem;
}
.document-viewer {
  margin: 1rem 2rem 4rem;
  height: 80vh;
}
.document-text {
  margin: 2rem auto;
  text-align: center;
}

.bestRatedImgSliderContainer img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 460px) {
  .bestRatedImgSliderContainer img {
    object-fit: contain;
  }
  .doc-pic {
    height: fit-content;
  }
}

@media screen and (min-width: 900px) {
  .document-title {
    margin: 3rem 30rem;
  }
  .document-viewer {
    margin: 0 auto 3rem;
    width: 60%;
  }
  .btnWrapperDocs {
    margin: 0 auto;
    width: 20rem;
  }
}
