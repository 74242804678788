@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}

html {
  background-color: #eaf0f2;
}

body {
  margin: 0;
  font-family: "Raleway", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(253, 253, 253);
}

.container {
  max-width: 1240px;
  margin: auto;
}

.btn {
  color: #fff;
  padding: 12px 24px;
  background: linear-gradient(
    90deg,
    rgba(45, 42, 48, 1) -50%,
    rgba(234, 160, 63, 1) 50%
  );
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  transition: 0.4s;
  cursor: pointer;
  font-family: "Noto Kufi Arabic", sans-serif;
}

.btn:ative {
  transform: translate(4px);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0, 0.2);
  border-bottom: none;
}
