.section {
  min-height: 100vh;
}

.container-about {
  width: 80%;
  margin: 6rem auto;
  padding: 3rem;
  display: flex;
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;
  border: solid 3px #6e7491;
  line-height: 28px;
  transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.container-about:hover {
  transform: translateY(-10px);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.content-section {
  margin-right: auto;
  width: 55%;
}
.image-section {
  float: right;
  width: 40%;
}
.image-section img {
  width: 100%;
  height: auto;
}
.content-section .title {
  text-transform: uppercase;
  font-size: 20px;
  text-decoration: underline;
  direction: rtl;
}
.content-section .content h3 {
  margin-top: 20px;
  color: #5d5d5d;
  font-weight: 1000;
  text-align: justify;
  direction: rtl;
}
.content-section .content p {
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 1.5;
}
.content-section .content .button {
  margin-top: 30px;
}
.content-section .content .button a {
  background-color: #3d3d3d;
  padding: 12px 40px;
  text-decoration: none;
  color: #fff;
  font-size: 25px;
  letter-spacing: 1.5px;
}
.content-section .content .button a:hover {
  background-color: #a52a2a;
  color: #fff;
}

.content .first-child,
.second-child {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.btnWrapper {
  display: flex;
}

.btnWrapper .btn {
  width: 20rem;
  padding: 0.4rem;
}

@media screen and (max-width: 900px) {
  .btnWrapper {
    flex-direction: column;
  }
  .btnWrapper .btn {
    width: 15rem;
    padding: 0.4rem;
    margin: 1rem auto;
  }
}

@media screen and (max-width: 768px) {
  .container-about {
    width: 80%;
    display: block;
    margin: 2rem auto;
  }
  .content-section {
    width: 100%;
    display: block;
    margin: auto;
  }
  .image-section {
    float: none;
    width: 100%;
  }
  .image-section img {
    width: 100%;
    height: auto;
    margin: 2rem auto;
  }
  .content-section .title {
    text-align: center;
    font-size: 19px;
  }
  .content-section .content .button {
    text-align: center;
    margin-bottom: 2rem;
  }
  .content-section .content .button a {
    padding: 9px 30px;
  }
  .btnWrapper {
    flex-direction: column;
  }
  .btnWrapper .btn {
    width: 13rem;
    padding: 0.4rem;
  }
}
