.navbar {
  background-color: #eee;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.house-icon {
  margin-right: 0.6rem;
}

.navbar-icon-text {
  margin-left: 1rem;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #000;
}

.nav-menu {
  background-color: #ffffff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 1;
}

.nav-menu.active {
  left: 0;
  transition: 450ms;
  background-color: #eee;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #151414;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a span {
  padding: 1rem;
}

.nav-text a:hover {
  background-color: #000000;
  color: #fff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #eee;
}

.navbar span {
  margin-left: 16px;
  font-size: 24px;
  color: #eaa03f;
}

@media screen and (max-width: 478px) {
  .navbar span {
    font-size: 20px;
  }
  .navbar h1 {
    font-size: 30px;
  }
}
