.hero-container {
  background: url(../../images/hero-bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 65vh;
}

.hero-main-image {
  background: url(../../images/elhamForouhar.png) no-repeat center;
  background-size: cover;
  height: 500px;
  width: 100%;
  position: absolute;
}

.content-container {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin-top: 15rem;
}

.content-container h1 {
  text-align: center;
  align-items: center;
  color: #eaf0f2;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 2rem;
  -webkit-text-stroke: 2px black;
}

.hero-button {
  text-align: center;
  margin: 0 auto;
}
@media screen and (min-width: 380px) and (max-width: 480px) {
  .hero-main-image {
    margin-top: 2rem;
  }

  .content-container {
    margin-top: 20rem;
  }

  .content-container h1 {
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 814px) {
  .hero-container {
    height: 100vh;
    flex-direction: row-reverse;
  }
  .hero-main-image {
    background-size: cover;
    height: 100vh;
    width: 50%;
    position: relative;
  }
  .content-container {
    margin-top: 0;
    width: 50%;
  }

  .content-container h1 {
    margin: 0 auto 1rem;
    max-width: 35rem;
  }
}
@media screen and (min-width: 821px) {
  .hero-container {
    height: 100vh;
    flex-direction: row-reverse;
  }
  .hero-main-image {
    background-size: cover;
    height: 100vh;
    width: 50%;
    position: relative;
  }
  .content-container {
    margin-top: 0;
    width: 50%;
  }

  .content-container h1 {
    margin: 0 auto 1rem;
    max-width: 35rem;
  }
}

@media screen and (min-width: 1024px) {
  .content-container h1 {
    font-size: 2.5rem;
    width: 100%;
  }
}

@media screen and (min-width: 2024px) {
  .content-container h1 {
    font-size: 3.6rem;
    max-width: 65rem;
  }
}
