.featured {
  padding: 1rem;
  margin: 3rem auto 0;
}

.component-container {
  border: 1px solid;
  margin-bottom: 2rem;
  border-radius: 0.6rem;
  padding: 2rem 3rem;
}

.component-container ol {
  list-style-type: arabic-indic;
}

.featured-text-advantages-title,
.featured-text-title {
  font: lighter;
  text-align: center;
  padding-top: 3rem;
}

.featured-text-advantages,
.feature-text {
  font: lighter;
  text-align: right;
  margin-top: 2rem;
  direction: rtl;
}
.featured-text-advantages-bold,
.featured-text-bold {
  font-weight: 900;
  text-align: right;
  margin-top: 2rem;
  direction: rtl;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.info-details {
  display: flex;
  justify-content: center;
}

.info-details h2 {
  text-align: center;
}

.info-buttons {
  width: 80%;
  margin: 1.2rem auto;
  display: flex;
  flex-direction: column;
}

.info-buttons button {
  margin: 0.6rem;
}

.info-description {
  width: 95%;
  margin: 1.2rem auto 1rem;
  text-align: justify;
}

.info-details-purpose {
  text-align: center;
}

.info-details-data {
  margin-top: 2rem;
}

.info-details-data-single {
  display: flex;
  justify-content: space-between;
  width: 20rem;
  margin: 1rem auto;
}

.info-details-data-single-title {
  font-weight: 600;
}

.hint-text {
  border: 1px solid;
  margin: 2rem auto;
  background: rgba(234, 160, 63, 1);
  text-align: center;
  padding: 3rem;
}

@media screen and (min-width: 500px) {
  .info-details-data-single {
    width: 35rem;
  }
  .info-buttons {
    width: 70%;
  }
}

@media screen and (min-width: 900px) {
  .info-buttons {
    width: 30%;
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .info-buttons {
    width: 40%;
    justify-content: space-around;
  }
}
